
@font-face {
  font-family: 'Generalsans';
  src: url('../assets/fonts/GeneralSans-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Generalsans';
  src: url('../assets/fonts/GeneralSans-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Generalsans';
  src: url('../assets/fonts/GeneralSans-Extralight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Generalsans';
  src: url('../assets/fonts/GeneralSans-ExtralightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Generalsans';
  src: url('../assets/fonts/GeneralSans-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Generalsans';
  src: url('../assets/fonts/GeneralSans-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Generalsans';
  src: url('../assets/fonts/GeneralSans-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Generalsans';
  src: url('../assets/fonts/GeneralSans-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Generalsans';
  src: url('../assets/fonts/GeneralSans-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Generalsans';
  src: url('../assets/fonts/GeneralSans-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Generalsans';
  src: url('../assets/fonts/GeneralSans-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Generalsans';
  src: url('../assets/fonts/GeneralSans-SemiboldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}